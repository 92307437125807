import Vue from 'vue';
import SchoolModule from '@/store/modules/SchoolModule';
import { getModule } from 'vuex-module-decorators';
import Component from 'vue-class-component';
import { getSchoolPrefix } from '@/utils/common';

@Component({})
export default class TutorialMixin extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);

  get isTutorialOnlyVersion(): boolean {
    return this.schoolModule.isTutorialOnlyVersion;
  }

  redirectOnTutorialOnly(): void {
    const form = this.$route.params.form;
    const prefix = getSchoolPrefix();
    this.$router.push({ path: `/${prefix}/tests/${form}` });
  }
}
