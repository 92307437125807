var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{staticClass:"fantasy__answer d-flex flex-column justify-center align-center pa-0",attrs:{"cols":"12","sm":"10","md":"10","lg":"8","xl":"7"}},[(!_vm.started)?_c('TutorialEndEmoji',{attrs:{"text":_vm.$t('tests.go-start'),"disabled":_vm.disableButtons},on:{"start":function($event){_vm.started = true;
      _vm.startInitial();}}}):_vm._e(),(_vm.started && !_vm.isEnd)?[_c('FantasyWordQuestion',{staticClass:"fantasy__question",attrs:{"question":_vm.currentQuestion.solution}}),_c('v-col',{staticClass:"fantasy__options d-flex justify-center align-start mt-6 pa-0 flex-grow-4",attrs:{"cols":"12"}},[_c('FantasyWordAnswerOption',{key:`option-${0}`,class:{ disabledButton: _vm.disableButtons },attrs:{"image":_vm.inactiveImages[0],"activeImage":_vm.activeMax,"showBubble":_vm.showMaxBubble,"format":_vm.format,"answerLocked":_vm.answerLocked},nativeOn:{"click":function($event){return _vm.answerQuestion(_vm.currentQuestion.options[0])}}}),_c('FantasyWordAnswerOption',{key:`option-${1}`,class:{ disabledButton: _vm.disableButtons },attrs:{"image":_vm.inactiveImages[1],"activeImage":_vm.activeLisa,"showBubble":_vm.showLisaBubble,"format":_vm.format,"answerLocked":_vm.answerLocked},nativeOn:{"click":function($event){return _vm.answerQuestion(_vm.currentQuestion.options[1])}}}),_c('FantasyWordAnswerOption',{key:`option-${2}`,class:{ disabledButton: _vm.disableButtons },attrs:{"image":_vm.inactiveImages[2],"activeImage":_vm.activeBoth,"format":_vm.format,"answerLocked":_vm.answerLocked},nativeOn:{"click":function($event){return _vm.answerQuestion(_vm.currentQuestion.options[2])}}})],1),_c('audio',{ref:"maxAudio",attrs:{"src":`/audio/fantasy/form-${_vm.currentForm}/${_vm.currentQuestion.options[0]}.mp3`},on:{"ended":function($event){_vm.showMaxBubble = false;
        _vm.activeMax = '';
        _vm.play(1);}}}),_c('audio',{ref:"lisaAudio",attrs:{"src":`/audio/fantasy/form-${_vm.currentForm}/${_vm.currentQuestion.options[1]}.mp3`},on:{"ended":function($event){_vm.showLisaBubble = false;
        _vm.activeMax = _vm.activeImages[0];
        _vm.activeLisa = _vm.activeImages[1];
        _vm.activeBoth = _vm.activeImages[2];
        _vm.disableButtons = false;
        _vm.startTimer();}}})]:_vm._e(),_c('audio',{ref:"testEndSound",attrs:{"src":'/audio/grossartig.mp3'}}),(_vm.isEnd && _vm.started)?[_c('TestEndEmoji',{attrs:{"saving":_vm.loadingSave,"save-success":_vm.saveSuccess,"reached-max-retries":_vm.reachedMaxRetries,"module":_vm.fantasyModule}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }