import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class RetryMixin extends Vue {
  // RETRY
  loadingSave = false;
  saveSuccess = false;
  MAX_RETRIES = 1;
  currentRetries = 0;

  get reachedMaxRetries(): boolean {
    return this.currentRetries >= this.MAX_RETRIES;
  }
}
