
import FantasyModule from '@/store/modules/FantasyModule';
import UserModule from '@/store/modules/UserModule';
import { FantasyWordQuestionType } from '@/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TestEndEmoji from '../../base/TestEndEmoji.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import RetryMixin from '../RetryMixin';
import FantasyWordAnswerOption from './FantasyWordAnswerOption.vue';
import FantasyWordQuestion from './FantasyWordQuestion.vue';

@Component({
  components: {
    FantasyWordQuestion,
    FantasyWordAnswerOption,
    TestEndEmoji,
    TutorialEndEmoji,
  },
})
export default class FantasyWordContainer extends RetryMixin {
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  @Ref() maxAudio!: HTMLAudioElement;
  @Ref() lisaAudio!: HTMLAudioElement;
  @Ref() testEndSound!: HTMLAudioElement;

  activeImages = [
    '/images/fantasy/Max',
    '/images/fantasy/Lisa',
    '/images/fantasy/Max-and-Lisa',
  ];
  inactiveImages = [
    '/images/fantasy/Max_ALT',
    '/images/fantasy/Lisa_ALT',
    '/images/fantasy/Max-and-Lisa_ALT',
  ];

  activeMax = '';
  showMaxBubble = false;
  activeLisa = '';
  showLisaBubble = false;
  activeBoth = '';
  disableButtons = true;
  showTutorialEnd = true;
  started = false;
  answerLocked = false;

  start = 0;
  stop = 0;

  // in ms
  delayBetweenMaxAndLisa = 1000;

  get currentQuestion(): FantasyWordQuestionType {
    return this.fantasyModule.currentQuestion;
  }

  get currentForm(): string {
    return this.fantasyModule.currentForm;
  }

  get isEnd(): boolean {
    return (
      this.fantasyModule.overallQuestions ===
      this.fantasyModule.result.questionsAnswered
    );
  }

  get format(): string {
    return this.fantasyModule.imageFormat;
  }

  mounted(): void {
    // disable start test button until 'Mache es so schnell, wie du kannst.' ended
    setTimeout(() => {
      this.disableButtons = false;
    }, 7500);
  }

  startTimer(): void {
    // INFO enable answering again
    this.answerLocked = false;
    this.start = performance.now();
  }

  play(value: number): void {
    let isFirst = value === 0;

    //set correct active image
    setTimeout(
      async () => {
        if (isFirst) {
          this.userModule.setActiveAudio(this.maxAudio);
          this.maxAudio?.addEventListener(
            'play',
            () => {
              this.activeMax = this.activeImages[0];
              this.showMaxBubble = true;
            },
            { once: true },
          );
          this.maxAudio.play();
        } else {
          this.userModule.setActiveAudio(this.lisaAudio);
          this.lisaAudio?.addEventListener(
            'play',
            () => {
              this.activeLisa = this.activeImages[1];
              this.showLisaBubble = true;
            },
            { once: true },
          );
          this.lisaAudio.play();
        }
      },
      // INFO after answering question let Max speak immediately
      isFirst ? 300 : this.delayBetweenMaxAndLisa,
    );
  }

  startInitial(): void {
    this.disableButtons = true;
    this.play(0);
  }

  async updated(): Promise<void> {
    if (
      this.isEnd &&
      !this.loadingSave &&
      !this.saveSuccess &&
      this.currentRetries < this.MAX_RETRIES
    ) {
      try {
        this.loadingSave = true;
        this.currentRetries++;

        const res = await this.fantasyModule.saveStatus(
          this.userModule.currentUser._id,
        );

        if (res?.status === 200) {
          this.saveSuccess = true;
          this.fantasyModule.finishedAndSavedTest();
        }
      } catch (error: any) {
        this.fantasyModule.resetDone();
        console.error(
          '[FANTASY] error saving status',
          error?.code,
          error?.message,
          error?.name,
          error?.response?.data?.error,
          error?.response?.data?.message,
          error?.response?.data?.statusCode,
        );
      } finally {
        this.loadingSave = false;
      }
    }
  }

  answerQuestion(answer: string): void {
    if (this.answerLocked) return;
    this.answerLocked = true;
    this.stop = performance.now();
    const time = this.stop - this.start;
    this.disableButtons = true;
    setTimeout(() => {
      this.fantasyModule.addAnswer({
        solution: this.currentQuestion.solution,
        answer: answer,
        time: Math.round(time),
        isCorrect: answer === this.currentQuestion.solution,
      });

      //reset images
      this.activeMax = '';
      this.activeLisa = '';
      this.activeBoth = '';
      if (this.isEnd) {
        this.userModule.setActiveAudio(this.testEndSound);
        this.testEndSound.play();
      }
      //play next audio
      else {
        //short delay before next question
        setTimeout(async () => {
          this.play(0);
        }, 500);
      }
    }, 500);
  }
}
