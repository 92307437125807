var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isEnd)?_c('v-col',{staticClass:"fantasy__answer d-flex flex-column justify-center align-center pa-0",class:_vm.started ? '' : 'top-margin',attrs:{"cols":"12","sm":"10","md":"10","lg":"8","xl":"7"}},[(_vm.started)?_c('RepeatButton',{attrs:{"isDisabled":_vm.disableButtons},nativeOn:{"click":function($event){return _vm.repeatQuestion.apply(null, arguments)}}}):_vm._e(),(!_vm.started)?_c('StartTestButton',{on:{"start":function($event){_vm.started = true;
      _vm.playIntro();}}}):_vm._e(),(_vm.started)?[_c('FantasyWordQuestion',{staticClass:"fantasy__question",attrs:{"question":_vm.currentQuestion.solution}}),_c('v-col',{staticClass:"fantasy__options d-flex justify-center align-start mt-6 pa-0 flex-grow-4",attrs:{"cols":"12"}},[_c('FantasyWordAnswerOption',{key:`option-${0}`,class:{
          disabledButton:
            _vm.disableButtons &&
            !(
              _vm.currentQuestion.options[0] === _vm.currentQuestion.solution &&
              _vm.enableSolution
            ),
          solution: _vm.currentQuestion.options[0] === _vm.currentQuestion.solution,
          wrongAnswer: _vm.currentQuestion.options[0] != _vm.currentQuestion.solution,
        },attrs:{"image":_vm.inactiveImages[0],"activeImage":_vm.activeMax,"showBubble":_vm.showMaxBubble,"isTutorial":true,"format":_vm.format,"answerLocked":_vm.answerPlaying},nativeOn:{"click":function($event){return _vm.answerQuestion(_vm.currentQuestion.options[0])}}}),_c('FantasyWordAnswerOption',{key:`option-${1}`,class:{
          disabledButton:
            _vm.disableButtons &&
            !(
              _vm.currentQuestion.options[1] === _vm.currentQuestion.solution &&
              _vm.enableSolution
            ),
          solution: _vm.currentQuestion.options[1] === _vm.currentQuestion.solution,
          wrongAnswer: _vm.currentQuestion.options[1] != _vm.currentQuestion.solution,
        },attrs:{"image":_vm.inactiveImages[1],"activeImage":_vm.activeLisa,"showBubble":_vm.showLisaBubble,"format":_vm.format,"answerLocked":_vm.answerPlaying},nativeOn:{"click":function($event){return _vm.answerQuestion(_vm.currentQuestion.options[1])}}}),_c('FantasyWordAnswerOption',{key:`option-${2}`,class:{
          disabledButton:
            _vm.disableButtons &&
            !(
              _vm.currentQuestion.options[2] === _vm.currentQuestion.solution &&
              _vm.enableSolution
            ),
          solution: _vm.currentQuestion.options[2] === _vm.currentQuestion.solution,
          wrongAnswer: _vm.currentQuestion.options[2] != _vm.currentQuestion.solution,
        },attrs:{"image":_vm.inactiveImages[2],"activeImage":_vm.activeBoth,"format":_vm.format,"answerLocked":_vm.answerPlaying},nativeOn:{"click":function($event){return _vm.answerQuestion(_vm.currentQuestion.options[2])}}})],1)]:_vm._e(),_c('audio',{ref:"currentAudio",attrs:{"src":_vm.audioSrc},on:{"ended":_vm.playSound}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }