
import { GetClientDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

@Component
export default class ClientDeleteDialog extends Vue {
  @Prop() show!: boolean;
  @Prop() client?: GetClientDto;
  @Prop() type!: 'client' | 'students';

  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  clientModule: ClientModule = getModule(ClientModule, this.$store);

  loadingStudents = false;
  loadingClient = false;

  get isClientToDelete(): boolean {
    return this.type === 'client';
  }

  get name(): string {
    return this.client?.schoolName ?? '';
  }

  get numOfStudents(): string {
    return (
      this.client?.numOfStudents?.toString() ?? 'Kundendaten nicht gefunden'
    );
  }

  async deleteStudents() {
    if (!this.client) return;
    try {
      this.loadingStudents = true;

      await this.clientModule.deleteStudents(this.client.schoolId!);
      this.snackbarModule.showMessage({
        message: this.$i18n
          .t('client.snackbar.delete.students.success')
          .toString(),
        isSuccess: true,
      });
      this.deleted();
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n
          .t('client.snackbar.delete.students.error')
          .toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingStudents = false;
    }
  }

  // TODO fix button not calling deleteClient
  async deleteClient() {
    if (!this.client) return;
    try {
      this.loadingClient = true;
      await this.clientModule.deleteClient(this.client.id);
      this.snackbarModule.showMessage({
        message: this.$i18n
          .t('client.snackbar.delete.client.success')
          .toString(),
        isSuccess: true,
      });
      await this.clientModule.fetchAllClients();
      setTimeout(() => this.$router.push({ name: 'groups' }), 1500);
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('client.snackbar.delete.client.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingClient = false;
    }
  }

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleted() {}

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cancel() {}
}
