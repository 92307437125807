
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { clickSound } from '../../../utils/common';

@Component
export default class FantasyWordsAnswerOption extends Vue {
  @Prop() image!: string;
  @Prop() activeImage!: string;
  @Prop() format!: string;
  @Prop({ default: false }) showBubble?: boolean;
  @Prop({ default: false }) isTutorial?: boolean;
  @Prop({ default: false }) answerLocked?: boolean;

  showOrangeBorder = false;

  showBorder(): void {
    if (this.answerLocked) return;
    clickSound.play();
    this.showOrangeBorder = true;
    if (this.isTutorial) {
      setTimeout(async () => {
        this.showOrangeBorder = false;
      }, 2000);
    } else {
      setTimeout(() => {
        this.showOrangeBorder = false;
      }, 1000);
    }
  }
  get active(): boolean {
    return this.activeImage !== '';
  }

  get bubble(): string {
    return require('@/assets/images/bubble.png');
  }
}
